
import './App.css';
import bisc from './Images/BiscLean.PNG'
import React, { useState } from 'react';
import axios from 'axios';



export default function FooterComp() {

  const [showBiscPop, setShowBiscPop] = useState(false);



  function sendMail() {

    let name = document.getElementById("name")as HTMLInputElement | null;
    let email = document.getElementById("email")as HTMLInputElement | null;
    let subject = document.getElementById("subject")as HTMLInputElement | null;
    let message = document.getElementById("message")as HTMLTextAreaElement | null;

   
 

    if (!name || !email || !subject || !message) {
      alert("something went wrong :/")
      return
    }

    let body =  {name:name.value,email:email.value,subject:subject.value,message:message.value};
   
    let url = `${process.env.REACT_APP_BACKEND}/SendMail`
    console.log(url)

    axios.post(
      url,
     body,
      {
        headers: {},
      },
    );

   
    alert("Message sent!");
  }

  return (
    <div>

      <div className="BiscSplit" >
        <img className="biscImg" src={bisc} onClick={() => { setShowBiscPop(!showBiscPop) }}></img>
        {showBiscPop ?
          <>
            <a className="biscPop">Wow... You made it this far?</a>
            {/* <div className="biscpoptriangle"></div>*/}
          </>
          :
          null}
      </div>




      <footer className='App-Footer'>
        <article id="contact" >
          <div >
            <header>
              <h2 className="font-bold text-xl">Don't hold back!</h2>
              <p>Questions, recomendations, criticism, nothings off limits!</p>
            </header>

            <div >

              <form id="PostMessage" className="m-5">



                <div className="grid grid-cols-2 m-2 gap-8">
                  <input type="text" className=" shadow appearance-none border rounded  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="name" id="name" placeholder="Name" />

                  <input type="text" className=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="email" id="email" placeholder="Email" />
                </div>
                <div className="columns-1 m-2">
                  <input type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="subject" id="subject" placeholder="Subject" />
                </div>
                <div className="columns-1 m-2">
                  <textarea name="message" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" placeholder="Message"></textarea>
                </div>
                <div className="float-right">
                  <div className="columns-1 m-2">
                    <input type="reset" value="Clear Form" id="ClrFormButton" className="m-2" />
                    <input type="reset" onClick={() => { sendMail() }} value="Send Message" className="hover:animate-bounce bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" />

                  </div>
                </div>

              </form>

              <hr className='w-full' />



            </div>
            <footer>

              {/*
            <ul id="copyright" className='columns-2'>
              <li>&copy; Untitled. All rights reserved.</li>
            </ul>
            */}
            </footer>
          </div>
        </article>
      </footer>

    </div>
  );
}
