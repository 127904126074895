import React from 'react';
import myFace from './Images/IMG_4083.jpg';
import './App.css';
import NavComp from './Navigaton';

interface Params { title: string;pageIndex:number }

export default function HeaderComp(headerInputs:Params ) {

  document.title=headerInputs.title;
  return (
    <header className="App-header">
        <img src={myFace} className="App-logo" alt="logo" />
       <NavComp pageIndex={headerInputs.pageIndex}/>
       
      

      </header>
  );
}
