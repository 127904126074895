
import '../App.css';
import './Cloud.css'
import { useIsVisible } from '../utilities/usIsVisible';
import { useRef } from 'react';

export default function SkillsComp() {

  const skillsRef = useRef<any>();
  const skillsIsVisible = useIsVisible(skillsRef)

  return (
    <div ref={skillsRef} className={`  m-8 transition-opacity ease-in duration-700 ${skillsIsVisible ? "opacity-100" : "opacity-0"}`} id="skills">
      <p className="text-2xl">Skills </p>
     
     <div className="cloudHolder">

    
      <ul className="cloud" role="navigation" aria-label="Webdev tag cloud">
  <li><a data-weight="4">HTTP</a></li>
  <li><a data-weight="8" >HTML</a></li>
  <li><a data-weight="6" >Tailwind</a></li>
  <li><a data-weight="8">C#</a></li>
  <li><a data-weight="2" >SQL</a></li>
  <li><a data-weight="9" >TypeScript</a></li>
  <li><a data-weight="4" >API</a></li>
  <li><a data-weight="7" >Agile</a></li>
  <li><a data-weight="2" >MongoDb</a></li>
  <li><a data-weight="8" >Git</a></li>
  <li><a data-weight="3" >Rest</a></li>
  <li><a data-weight="9" >JavaScript</a></li>
  <li><a data-weight="1">Linux</a></li>
  <li><a data-weight="7" >React</a></li>
  <li><a data-weight="8">CSS</a></li>
  <li><a data-weight="4" >GraphQL</a></li>
  <li><a data-weight="6" >UML</a></li>
</ul>
</div>

    </div>
  );
}
