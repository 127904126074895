
import '../App.css';
import kochind from '../Images/kochLong.PNG'
import ksSeal from '../Images/KstateLong.PNG'
import pecimg from '../Images/PECIMG.svg'
import { useRef } from 'react';
import { useIsVisible } from '../utilities/usIsVisible';


export default function WorkComp() {

  const schoolRef= useRef<any>()
  const schoolIsVisible = useIsVisible(schoolRef);

  const internRef = useRef<any>();
  const internIsVisible = useIsVisible(internRef);

  const kochRef = useRef<any>();
  const kochIsVisible = useIsVisible(kochRef);

  const pecRef = useRef<any>();
  const pecIsVisible = useIsVisible(pecRef);



  return (
    <div className="m-8 " id="work">
      <p className="text-2xl mb-7">My Employment </p>



      <section ref={pecRef} className={` columns-2 gap-0 transition-opacity ease-in duration-700 ${pecIsVisible ? "opacity-100" : "opacity-0"}`}>
        <div className="SeaelHolder columns-2">
        <div className="imgDiv">
          <img alt="Professional Engineering Consultants" src={pecimg}></img>
          </div>
          <div className="TimeLine">

          <a>Current</a>
              <div className="dotted-line "/>
              <a>May 2024</a>
              

          </div>
        </div>

      <div className="RightColWork">
      <p className="w-full text-start font-bold">Full-Stack Software Developer</p>
      <ul className="list-disc text-start">
        <li>
          One
        </li>
        <li>
          two
        </li>
        <li>
          three
        </li>
        <li>
          four
        </li>
        <li>
          five
        </li>
      </ul>
      </div>

       
      </section>


      <section ref={kochRef} className={` columns-2 gap-0 transition-opacity ease-in duration-700 ${kochIsVisible ? "opacity-100" : "opacity-0"}`}>
        <div className="SeaelHolder columns-2">
        <div className="imgDiv">
          <img alt="Koch Industries " src={kochind}></img>
          </div>
          <div className="TimeLine">

          <a>May 2024</a>
            <div className="dotted-line "/>
            <a>January 2023</a>
            

          </div>
        </div>


        <div className="RightColWork">
      <p className="w-full text-start font-bold">Automation Specialist</p>
      <ul className="list-disc text-start">
        <li>
        Primarily focused on the automation needs of Koch's legal capability. Including but not limited to,
        the automation of renewing trademarks.
        </li>
        <li>
        Develop automations using UiPath, Power Automate, PowerShell, JavaScript, and many more.

        </li>
        <li>
        Gather requirements for future automations and determine feasibility and timelines.
        </li>
        <li>
        Support and make enhancements to previously developed automations.
        </li>
       
      </ul>
      </div>
      </section>

      <section ref={internRef} className={` columns-2 gap-0 transition-opacity ease-in duration-700 ${internIsVisible ? "opacity-100" : "opacity-0"}`}>
        <div className="SeaelHolder columns-2">

        <div className="imgDiv">
          <img alt="Koch Industries"  src={kochind}></img>
          </div>
          <div className="TimeLine">

          <a>August 2022</a>
            <div className="dotted-line "/>
            <a>May 2022</a>


          </div>
        </div>
        <div className="RightColWork">
      <p className="w-full text-start font-bold">Automation Devloper Intern</p>
      <ul className="list-disc text-start">
      <li>
        Gained experience with Agile scrum methodology.
        </li>
        <li>
        Worked closely with customers to automate repetative business processes, utilizing software development skills and knowledge.
        </li>
        <li>
        Experimenting with OCR software including but not limited to Textract, Tesseract, and ABBYY.
        </li>
        <li>
        Developing programs using PowerShell, UiPath, Visual Studio Code, and many other tools.
        </li>
        <li>
        2022 Koch Innovation Challenge finalist.
        </li>
      </ul>
      </div>
       
      </section>


      <section ref={schoolRef} className={` columns-2 gap-0 transition-opacity ease-in duration-700 ${schoolIsVisible ? "opacity-100" : "opacity-0"}`}>
        <div className="SeaelHolder columns-2">
          <div className="imgDiv">
          <img alt="Kansas State University "  src={ksSeal}></img>
          </div>
         

          <div className="TimeLine">

          <a >December 2022</a>
            <div className="dotted-line "/>
            <a>August 2018</a>
            
          </div>

        </div>
        <div className="RightColWork">
      <p className="w-full text-start font-bold">Bachelors of Science in Computer Science</p>
      <ul className="list-disc text-start">
        <li>
        College of Engineering Dean`s List 2022.
        </li>
        <li>
        Robotics Competition Team Developer.
        </li>
        <li>
        Cumulative GPA: 3.1/4.0.
        </li>
     
      </ul>
      </div>
    
      </section>

    </div>
  );
}
