
import '../App.css';

export default function IntroComp() {

 
  return (
    <div className="m-8" id="intro">
        <p className="text-2xl">Hi! I'm <b>Mason Lumley</b>. Thanks for stopping by!</p>
        <div className="grid-cols-2">
           {/* <img src={myFace} className="App-logo " alt="logo" />*/}
            <p className="text-lg m-2 text-left ">
              I am a full stack software developer located in Wichita Kansas. 
              I graduated from Kansas State University with my Bachelors of Science in Computer Science. 
             I am currently developing software to aid engineers and streamline proccesses in the 
             AEC (Architecture, Engineering, and Construction) industry. 
             My current roles involves everything from gathering requirments, designing the software architecture 
              and sitting down codeing the dang thing! I am always looking to learn new things and 
              make connections. At any point, don't hesistate to <a className="underline font-blue" href="#contact">reach out</a>!
              Please take some time to learn about me!
              </p>

        </div>
    </div>
  );
}
