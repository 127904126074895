import React from 'react';
import HeaderComp from './Header';
import FooterComp from './Footer';
import IntroComp from './MainPageComps/IntroComp';
import WorkComp from './MainPageComps/WorkComp';
import PortfolioComp from './MainPageComps/PortfolioComp';
import SkillsComp from './MainPageComps/SkillsComp';
import './App.css';


function App() {

 
  return (
    <div className="App bg-slate-300 ">
      <HeaderComp title={"About Me!"} pageIndex={0}/>
      <body className="pageContents">
        <IntroComp/>  
        <SkillsComp/>
        <WorkComp/>
        <PortfolioComp/>
      
      </body>
      
      <FooterComp/>
     
    </div>
  );
}

export default App;
